import Upload from "../components/base/forms/Upload";
import { useOutletContext } from "react-router-dom";
import { Component, Fragment, useEffect } from "react";
import DirTree from "../components/voidcloud/DirTree";
import "../components/voidcloud/VoidCloud.css";
import { apiFetch } from "../util";

const { Dir, File } = DirTree;
const { UploadForm } = Upload;

export default function VoidCloud() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = "VoidCloud";
  }, []);
  return <VCContent user={user} setUser={setUser}></VCContent>;
}

class VCContent extends Component {
  constructor(props) {
    super(props);
    this.LS = JSON.parse(localStorage.getItem("voidcloud"));
    this.state = {
      user: props.user,
      activePath: "/",
      totalSize: 0,
      sizeMax: 200 * Math.pow(1024, 3),
      openDirs:
        !!this.LS && !!this.LS.openDirs ? this.LS.openDirs : { root: true },
      dirTreeJSX: false,
      showUpload: false,
      uploadProgress: 0,
    };
    this.buildShareList = this.buildShareList.bind(this);
    this.buildDirTree = this.buildDirTree.bind(this);
    this.closeUpload = this.closeUpload.bind(this);
    this.toggleDir = this.toggleDir.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
  }

  componentDidMount() {
    if (
      !!this.state.user &&
      !this.state.user.flags.cloud &&
      !this.state.user.files
    ) {
      const fileParams = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          jwt: this.state.user.jwt,
        },
        method: "GET",
      };
      apiFetch(`files`, fileParams).then((res) => {
        let userOut = this.state.user;
        userOut.files = res.data;
        userOut.jwt = res.jwt;
        this.setState({ user: userOut });
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.user !== this.props.user &&
      !!this.props.user &&
      !this.props.user.flags.cloud &&
      !!this.props.user.files
    )
      this.setState({
        dirTreeJSX: this.props.user.files.owned
          ? this.buildDirTree(
              this.state.totalSize > this.state.sizeMax,
              this.props.user.files.owned
            )
          : false,
      });
    if (oldProps.user !== this.props.user && !this.props.user) {
      this.setState({ dirTreeJSX: false });
    }
  }
  calcDisplaySize(size) {
    const sizeKB = size / 1024;
    const sizeMB = sizeKB / 1024;
    const sizeGB = sizeMB / 1024;
    const sizeTB = sizeGB / 1024;
    return sizeTB > 1
      ? `${sizeTB.toFixed(2)} TB`
      : sizeGB > 1
      ? `${sizeGB.toFixed(2)} GB`
      : sizeMB > 1
      ? `${sizeMB.toFixed(2)} MB`
      : sizeKB > 1
      ? `${sizeKB.toFixed(2)} KB`
      : `${size} B`;
  }

  toggleDir(id) {
    let dirsOut = this.state.openDirs;
    dirsOut[id] = !dirsOut[id];
    let LS = JSON.parse(localStorage.getItem("voidcloud"));
    if (!!LS) LS.openDirs = dirsOut;
    else LS = { openDirs: dirsOut };
    localStorage.setItem("voidcloud", JSON.stringify(LS));
    this.setState({
      openDirs: dirsOut,
    });
  }

  buildShareList(files = this.props.user.files.shared, parent = "shareRoot") {
    return (
      <Fragment>
        {this.state.openDirs[parent] && (
          <ul>
            {files.map((f) => {
              return (
                <li className="treeItemContainer" key={f._id}>
                  <File
                    share={true}
                    name={f.name}
                    file={f}
                    jwt={this.props.user.jwt}
                    user={this.props.user}
                    setUser={this.props.setUser}
                    setJWT={(jwt) => {
                      let user = this.props.user;
                      user.jwt = jwt;
                      this.props.setUser(user);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </Fragment>
    );
  }

  buildDirTree(overSize, files = this.props.user.files.owned, parent = "root") {
    if (parent === "root") {
      let totalSize = 0;
      files.forEach((f) => {
        totalSize += f.size;
      });
      if (totalSize !== this.state.totalSize)
        this.setState({ totalSize: totalSize });
    }
    return (
      <Fragment>
        {this.state.openDirs[parent] && (
          <ul>
            {files
              .filter((file) => file.parent === parent)
              .map((f) => {
                return (
                  <li className="treeItemContainer" key={f._id}>
                    {f.type === "f" && (
                      <File
                        name={f.name}
                        size={this.calcDisplaySize(f.size)}
                        file={f}
                        jwt={this.props.user.jwt}
                        user={this.props.user}
                        setUser={this.props.setUser}
                        setJWT={(jwt) => {
                          let user = this.props.user;
                          user.jwt = jwt;
                          this.props.setUser(user);
                        }}
                      />
                    )}
                    {f.type === "d" && (
                      <div className="treeItemDir">
                        <Dir
                          jwt={this.props.user.jwt}
                          file={f}
                          toggleDir={this.toggleDir}
                          user={this.props.user}
                          setUser={this.props.setUser}
                          overSize={overSize}
                        />
                        {this.state.openDirs[f._id] &&
                          this.buildDirTree(overSize, files, f._id)}
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>
        )}
      </Fragment>
    );
  }

  closeUpload() {
    this.setState({ showUpload: false });
  }

  updateProgress(int) {
    if (int >= 100) {
      this.setState({ uploadProgress: int }).then(() => {
        setTimeout(() => {
          this.setState({ uploadProgress: 0 });
        }, 1000 * 5);
      });
    } else {
      this.setState({ uploadProgress: int });
    }
  }

  render() {
    const { showUpload, totalSize, sizeMax, uploadProgress } = this.state;
    const { user } = this.props;
    const overSize = totalSize > sizeMax;
    return (
      <Fragment>
        {!user && (
          <Fragment>
            <h2>Welcome to Void Cloud!</h2>
            <h3>Please log in or sign up at the top right!</h3>
            <h3>
              Since this is a private cloud platform, if you're a new user, you
              need to request access with a verified email, but you're welcome
              to use my other services with the same account across all of
              speags.com!
            </h3>
            <h3>
              Click <a href="https://speags.com">here</a> for the homepage.
            </h3>
          </Fragment>
        )}
        {user && !user.flags.cloud && (
          <Fragment>
            {!!user.files && user.files.owned && user.files.owned.length > 0 ? (
              <Fragment>
                <div className="treeItemDir">
                  <Dir
                    jwt={this.props.user.jwt}
                    file={{ name: "Your Files:", _id: "root" }}
                    toggleDir={this.toggleDir}
                    user={this.props.user}
                    setUser={this.props.setUser}
                    overSize={overSize}
                  />
                  {this.buildDirTree(overSize)}
                </div>
                <div
                  className={`storageSize ${overSize ? "storageOverSize" : ""}`}
                >
                  {`Storage usage: ${this.calcDisplaySize(
                    totalSize
                  )} / ${this.calcDisplaySize(sizeMax)}`}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <h2>You haven't uploaded any files yet...</h2>
                <button
                  onClick={() => {
                    this.setState({ showUpload: true });
                  }}
                >
                  Upload
                </button>
                {showUpload && (
                  <UploadForm
                    jwt={user.jwt}
                    parent={"root"}
                    closeUpload={this.closeUpload}
                    setUser={(user) => {
                      this.closeUpload();
                      this.props.setUser(user);
                    }}
                    reportProgress={this.updateProgress}
                  />
                )}
              </Fragment>
            )}
            {!!user.files &&
              user.files.shared &&
              user.files.shared.length > 0 && (
                <Fragment>
                  <div className="treeItemDir">
                    <Dir
                      share={true}
                      jwt={this.props.user.jwt}
                      file={{
                        name: "Files shared with you:",
                        _id: "shareRoot",
                      }}
                      toggleDir={this.toggleDir}
                      user={this.props.user}
                      setUser={this.props.setUser}
                    />
                    {this.buildShareList()}
                  </div>
                </Fragment>
              )}
            {uploadProgress > 0 && (
              <div className="uploadProgress">
                Upload progress: {uploadProgress}%
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
