import { Fragment } from "react";
import Btn from "./Btn.jsx";

export default function NotFound() {
  return (
    <Fragment>
      <h2>404 - Page not found!</h2>
      <Btn key={1} title={"Back to Home"} href={"/"} />
    </Fragment>
  );
}
