import { Fragment, Component, useEffect, createRef } from "react";
import { useOutletContext } from "react-router-dom";
import NotFound from "../components/base/NotFound.jsx";
import "../components/sadmin/sadmin.css";
import { io } from "socket.io-client";
import * as util from "../util.js";
import { Terminal } from "@xterm/xterm";
import "@xterm/xterm/css/xterm.css";

export default function Sadmin() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = "Server Admin Utility";
  }, []);
  return <SadminPage user={user} setUser={setUser} />;
}

class SadminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      socket: false,
      appList: [],
      backLog: [],
    };
    this.initSocket = () => {
      const socket = io("https://speags.com", { path: "/apis/user/sadminws" });
      socket.once("connect", () => {
        socket.once("auth_req", () => {
          socket.emit("auth_res", this.state.user.jwt);
        });
        socket.once("auth_grant", () => {
          socket.on("pm2_list", (list) => {
            this.setState({ appList: list });
          });
          socket.on("pm2_fail", (err) => {
            console.log("Pm2 command failed!");
            console.log(err);
          });
          socket.on("log", (logObj) => {
            let backLog = this.state.backLog;
            backLog.push(logObj);
            this.setState({ backLog: backLog });
          });
        });
        socket.emit("ready");
      });
      return socket;
    };
  }
  componentDidUpdate(oldProps, oldState) {
    if (!!oldProps.user && !this.props.user)
      return this.setState({ user: false });
    if (
      oldProps.user !== this.props.user ||
      oldState.user !== this.state.user
    ) {
      let { user } = this.state;
      if (oldProps.user !== this.props.user && !!this.props.user)
        user = this.props.user;
      if (oldProps.user !== this.props.user && !this.props.user) user = false;
      if (!this.state.socket && !!this.state.user && this.state.user.owner) {
        return this.setState({ socket: this.initSocket() });
      }
      if (!!this.props.user && !this.state.user)
        return this.setState({ user: user });
    }
  }
  componentDidMount() {
    if (!!this.state.user && !!this.state.user.owner) {
      return this.setState({ socket: this.initSocket() });
    }
  }
  componentWillUnmount() {
    if (!!this.state.socket) {
      this.state.socket.close();
      return this.setState({ socket: false });
    }
  }
  render() {
    const { socket, appList, user, backLog } = this.state;
    let appJSX;
    if (!!appList && appList.length > 0)
      appJSX = (
        <Fragment>
          {appList.map((v) => {
            return (
              <AppPanel
                v={v}
                appLogs={backLog.filter((logObj) => logObj.pName === v.name)}
                socket={socket}
              />
            );
          })}
        </Fragment>
      );
    return (
      <Fragment>
        {(!user || !user.owner) && <NotFound />}
        {!!user && (
          <div className="SadminPanelContainer">
            {!!socket && !!appJSX && !!user && !!user.owner && (
              <Fragment>
                <div className="HardwarePanel">
                  <ScomTerminal socket={socket} />
                </div>
                <div className="ServerPanel">{appJSX}</div>
              </Fragment>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

class AppPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      console: false,
    };
  }

  render() {
    const { v, socket } = this.props;
    return (
      <div className="sPanelItem" key={v.pm_id}>
        <div className="sPanelGridContainer">
          <h3 className="sPanelItemTitle">{v.name}</h3>
          <h4 className="sPanelItemStatus">{`Status: ${v.pm2_env.status}`}</h4>
          <h4 className="sPanelItemUptime">{`Uptime: ${
            v.pm2_env.status === "online"
              ? util.msToTime(Date.now() - v.pm2_env.pm_uptime, true)
              : "N/A"
          }`}</h4>
          <h5 className="sPanelItemResMem">{`RAM: ${(
            v.monit.memory /
            1024 /
            1024
          ).toFixed(2)} MB`}</h5>
          <h5 className="sPanelItemResCpu">{`CPU: ${v.monit.cpu}%`}</h5>
          <button
            className="startBtn"
            disabled={v.pm2_env.status === "online"}
            onClick={() => {
              socket.emit("pm2_start", v.name);
            }}
          >
            Start
          </button>
          <button
            className="restartBtn"
            disabled={v.pm2_env.status !== "online"}
            onClick={() => {
              socket.emit("pm2_restart", v.name);
            }}
          >
            Restart
          </button>
          <button
            className="stopBtn"
            disabled={v.pm2_env.status !== "online"}
            onClick={() => {
              socket.emit("pm2_stop", v.name);
            }}
          >
            Stop
          </button>
          <button
            className="showConsole"
            style={{
              backgroundColor: this.state.console
                ? "var(--Red1)"
                : "var(--Green1)",
            }}
            onClick={() => {
              this.setState({ console: !this.state.console });
            }}
          >{`${this.state.console ? "Hide" : "Show"} Console`}</button>
        </div>
        {this.state.console && (
          <ConsolePanel appName={v.name} logs={this.props.appLogs} />
        )}
      </div>
    );
  }
}

class ConsolePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: props.logs,
      name: props.appName,
      levelFilter: "",
      msgFilter: "",
      inputTimeout: false,
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.logs.length !== this.props.logs.length) {
      this.setState({ logs: this.props.logs });
    }
  }

  render() {
    const { logs, name, levelFilter, msgFilter, inputTimeout } = this.state;
    let dispayedLogs = logs.filter((lo) => {
      if (levelFilter !== "") {
        if (msgFilter !== "") {
          return (
            lo.level === levelFilter &&
            lo.message.toLowerCase().includes(msgFilter.toLowerCase())
          );
        }
        return lo.level === levelFilter;
      }
      if (msgFilter !== "") {
        return lo.message.toLowerCase().includes(msgFilter.toLowerCase());
      } else return true;
    });
    return (
      <div className="ConsoleContainer">
        <div className="ConsoleFilterContainer">
          <button
            className="ConsoleFilterButton"
            onClick={() => {
              this.setState({ levelFilter: "" });
            }}
            disabled={levelFilter === ""}
          >
            All
          </button>
          <button
            className="ConsoleFilterButton"
            onClick={() => {
              this.setState({ levelFilter: "info" });
            }}
            disabled={levelFilter === "info"}
          >
            Info
          </button>
          <button
            className="ConsoleFilterButton"
            onClick={() => {
              this.setState({ levelFilter: "warn" });
            }}
            disabled={levelFilter === "warn"}
          >
            Warn
          </button>
          <button
            className="ConsoleFilterButton"
            onClick={() => {
              this.setState({ levelFilter: "error" });
            }}
            disabled={levelFilter === "error"}
          >
            Error
          </button>
          <input
            type="text"
            placeholder="Search logs"
            onChange={(e) => {
              if (!!inputTimeout) clearTimeout(inputTimeout);
              this.setState({
                inputTimeout: setTimeout(() => {
                  this.setState({ msgFilter: e.target.value });
                }, 1000),
              });
            }}
          />
        </div>
        <ul className="LogsContainer">
          {dispayedLogs.map((l) => {
            let style = {};
            switch (l.level) {
              case "warn": {
                style = { color: "yellow" };
                break;
              }
              case "error": {
                style = { color: "red" };
                break;
              }
              default: {
                style = { color: "white" };
                break;
              }
            }
            let msg = l.message;
            try {
              msg = JSON.parse(l.message);
            } catch {}
            return (
              <li
                className="ConsoleItem"
                key={`${name}_${logs.indexOf(l)}`}
                style={style}
              >
                {JSON.stringify(msg, null, 2)
                  .split(`"`)
                  .join("")
                  .split("\\n")
                  .join("")}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

class ScomTerminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: props.socket,
    };
    this.termRef = createRef();
    this.term = null;
    this.inputBuffer = "";
    this.currentDir = "";
  }

  componentDidMount() {
    const { socket } = this.state;
    this.term = new Terminal({
      cursorBlink: true,
      fontFamily: "monospace",
    });
    this.term.open(this.termRef.current);

    socket.on("scom_term_dir", (data) => {
      this.term.write(data);
      this.currentDir = data;
    });
    socket.on("scom_term_out", (data) => {
      this.term.write(data);
    });
    socket.on("scom_term_close", (msg) => {
      this.term.write("\x1b[2K\r");
      this.term.write(`${msg}\n`);
    });
    socket.emit("scom_term_getDir");
    this.term.onData((data) => {
      if (data === "\x7F") {
        if (this.inputBuffer.length > 0) {
          this.inputBuffer = this.inputBuffer.slice(0, -1);
          this.term.write("\b \b");
        }
      } else if (data === "\r" || data === "\n") {
        socket.emit("scom_term_in", this.inputBuffer);
        this.term.write("\r\n");
        this.inputBuffer = "";
      } else {
        this.inputBuffer += data;
        this.term.write(data);
      }
    });

    this.adjustSize();

    window.addEventListener("resize", this.adjustSize);

    this.term.focus();
  }

  componentWillUnmount() {
    if (this.term) this.term.dispose();
    window.removeEventListener("resize", this.adjustSize);
  }

  adjustSize = () => {
    if (this.termRef.current) {
      const { offsetWidth, offsetHeight } = this.termRef.current;
      const { charWidth, charHeight } = this.getFontDims();

      console.log(charWidth, ["[TERM]"]);
      console.log(charHeight, ["[TERM]"]);

      const cols = Math.floor(offsetWidth / charWidth);
      const rows = Math.floor(offsetHeight / charHeight);

      this.term.resize(cols, rows);
    }
  };

  getFontDims = () => {
    const testElem = document.createElement("span");
    testElem.textContent = "M";
    testElem.style = {
      fontFamily: "monospace",
      position: "absolute",
      visibility: "hidden",
    };
    document.body.appendChild(testElem);

    const charWidth = testElem.offsetWidth;
    const charHeight = testElem.offsetHeight;

    document.body.removeChild(testElem);
    return { charWidth, charHeight };
  };

  render() {
    return (
      <div
        ref={this.termRef}
        style={{ height: "100%", width: "100%" }}
        className="scomTerm"
      />
    );
  }
}
